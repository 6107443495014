<template>
  <div>
    <ed-funcao :intFuncaoId="$route.params.intId" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdFuncao from "@/components/cadastro/funcao/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdFuncao },
  mounted() {
    if(!this.$route.params.intId){
      this.$router.push({ name: "cadastro.funcao.register" });
    }
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>